import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
  clearAllLocalStorage,
  getTimeElapsedFromLocalStorage,
  removeLocalStorageItem,
} from "./localStorageUtil";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Home() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataStr, setDataStr] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [popupPageCount, setPopupPageCount] = useState(1);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(0);

  const cookie = new Cookies();
  // const billableSubmittedTask = cookie.get("clickedTasks")
  //   ? cookie.get("clickedTasks").length
  //   : 0;

  const filterBillableItems = () => {
    const clickedTasks = cookie.get("clickedTasks");
    const clickedChildIds = clickedTasks ? clickedTasks : [];

    if (!data.billable || typeof data.billable !== "object") {
      return []; // Return an empty array if billable data is not available
    }

    if (clickedChildIds.length === 0) {
      return Object.keys(data.billable); // Return all keys if no clicked tasks
    }

    return Object.keys(data.billable).filter(
      (key) => !clickedChildIds.includes(data.billable[key].childId)
    );
  };

  const billableSubmittedTask = filterBillableItems().length;

  // Messages API
  const [userMessages, setUserMessages] = useState([]);
  const [newMessageCount, setNewMessageCount] = useState(0);

  const [therapistName, setTherapistName] = useState("");

  useEffect(() => {
    const token = cookie.get("logindata");

    if (token) {
      try {
        const { therapist_name } = token;
        setTherapistName(therapist_name);
      } catch (error) {
        console.error("Failed to parse token:", error);
      }
    }
  }, []);

  const fetchMessages = async () => {
    try {
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      // const length = userMessages.length;
      const response = await axios.get(`${APIEndpoints.Messages}${sf_id}`);
      const newMessages = response.data.data;
      setUserMessages(newMessages);
      // const newMessageCountUpdate = newMessageCount + response.data.new_msg_count;
      setNewMessageCount(newMessages.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const [alerts, setAlerts] = useState();

  const fetchAlerts = async () => {
    try {
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      const response = await axios.get(`${APIEndpoints.Alert}${sf_id}`);
      const alert_data = response.data;
      setAlerts(alert_data);
      setShow(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  const declineAvailability = async () => {
    try {
      toast.info("Updating.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      const response = await axios.get(
        `${APIEndpoints.DECLINE_AVAILABILITY}${sf_id}`
      );
      toast.success("Updated Successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShow(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);

  const navigatetoAvailability = () => {
    navigate("/therapist/availability");
  };

  const navigatetoRegions = () => {
    navigate("/therapist/regions");
  };

  const alertItems = [
    alerts?.Update_Availablity && {
      key: "Update_Availablity",
      title: "Please update your availability",
      description:
        "Keeping your availability up to date allows us to match you with cases that best fit your schedule.",
      buttons: [
        {
          label: "Update Availability",
          style: { backgroundColor: "#06235c", color: "white" },
          onClick: navigatetoAvailability,
        },
        // {
        //   label: "My Availability has not changed",
        //   style: { border: "1px solid #06235c" },
        //   onClick: declineAvailability,
        // },
      ],
    },
    alerts?.Update_Region && {
      key: "Update_Region",
      title: "Please update the areas you serve",
      description:
        "Keeping the “areas you serve” up to date allows us to match you with cases that best fit your schedule.",
      buttons: [
        {
          label: "Update Now",
          style: { backgroundColor: "#06235c", color: "white" },
          onClick: navigatetoRegions,
        },
      ],
    },
  ].filter(Boolean);

  useEffect(() => {
    fetchMessages();
    const intervalId = setInterval(fetchMessages, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [newMessageCount]);

  // END Message

  useEffect(() => {
    const value = getTimeElapsedFromLocalStorage("task-data");
    setLastUpdatedTime(value);
  }, []);

  const handleClearCookie = () => {
    removeCookie("logindata");
    // Clear all storage
    clearAllLocalStorage();
    window.location.href = "/login";
  };

  const RefreshHomeApi = () => {
    if (isRefreshing) {
      toast.info("Refresh will enabled after 1 minute.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    // Set the button to disabled
    setIsRefreshing(true);

    removeLocalStorageItem("task-data", 0);
    fetchData();

    setTimeout(() => {
      setIsRefreshing(false);
    }, 60000);
  };

  const fetchData = async () => {
    const cookie = new Cookies();
    const token = cookie.get("logindata");
    const { access_token, sf_id } = token;

    try {
      setIsLoading(true);
      const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);

      if (response.status === 200) {
        setData(response.data);

        const str = handalConvertString(response.data, billableSubmittedTask);
        setDataStr(str);

        // Set Data in Cookie for 30 minutes
        setLocalStorageWithExpiration("task-data", response.data, 30);
        const value = getTimeElapsedFromLocalStorage("task-data");
        setLastUpdatedTime(value);
        setIsLoading(false);
      }
    } catch (error) {
      const status_code = error.response.status;

      if (status_code >= 400 && status_code < 600) {
        console.error("Retrying..");
        try {
          const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);
          if (response.status === 200) {
            setData(response.data);
            const str = handalConvertString(
              response.data,
              billableSubmittedTask
            );
            setDataStr(str);
            // Set Data in Cookie for 30 minutes
            setLocalStorageWithExpiration("task-data", response.data, 30);
            const value = getTimeElapsedFromLocalStorage("task-data");
            setLastUpdatedTime(value);
            setIsLoading(false);
          }
        } catch (error) {
          console.log(`Error: ${error}`);
          console.error(
            `Request failed with status code ${error.response.status}`
          );
          setIsLoading(false);
        }
      } else {
        console.error(`Request failed with status code ${status_code}`);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="fixed-header container">
        <div className="d-flex justify-content-between">
          <div className="left-arrrow p-0"></div>
          <div className="d-flex justify-content-center text-center ">
            {/* <div className='container text-center'> */}
            <div className="col-5 mt-2" style={{ marginRight: "35px" }}>
              <img
                src="/Therapy Associates_Logo.png"
                alt="logo"
                style={{ maxWidth: "170px", cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </div>
            {/* </div> */}
          </div>
          <div className="option">
            <i
              className="fa-solid fa-arrow-right-from-bracket p-2"
              id="logout-btn"
              onClick={handleClearCookie}
            ></i>
            <Dropdown id="header-option">
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  border: "none",
                  background: "transparent",
                }}
              >
                <i className="fa-solid fa-ellipsis-vertical text-dark"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleClearCookie}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>{" "}
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <hr />
      </div>

      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5 ">
          <h5 className="my-4 ms-2">
            <strong>Welcome, {therapistName}</strong>
          </h5>
          {userMessages && userMessages.length > 0 && (
            <div className="accordion mb-3 bg-light" id="messageAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="messageHeading">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#messageCollapse"
                    aria-expanded="true"
                    aria-controls="messageCollapse"
                    style={{ backgroundColor: "#efefef" }}
                    onClick={() => {
                      console.log("click");
                      setNewMessageCount(0);
                    }}
                  >
                    <strong>
                      {userMessages.length} Message(s)
                      <i class="fa fa-bell mx-2" aria-hidden="true"></i>
                    </strong>

                    {newMessageCount > 0 && (
                      <span class="badge text-bg-success">
                        {newMessageCount} new
                      </span>
                    )}
                  </button>
                </h2>
                <div
                  id="messageCollapse"
                  className="accordion-collapse collapse fade show message-box"
                  aria-labelledby="messageHeading"
                  data-bs-parent="#messageAccordion"
                >
                  <div className="accordion-body">
                    <div className="all-messages">
                      {userMessages && userMessages.length > 0
                        ? userMessages.map((message, index) => (
                            <div key={index} className="message my-3">
                              <p className="message-text">
                                <small>{message.message}</small>
                              </p>
                            </div>
                          ))
                        : "No messages"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="box position-relative p-3 mb-3 rounded">
            <h5 className="ms-2 mb-2">
              <strong>Tasks</strong>
            </h5>
            <Link to="/task" className="linkbox">
              <div className="d-flex justify-content-between">
                {(data.billable && Object.keys(data.billable).length > 0) ||
                (data.not_billable &&
                  Object.keys(data.not_billable).length > 0) ||
                (data.form25task && Object.keys(data.form25task).length > 0) ||
                (data.missing_eims &&
                  Object.keys(data.missing_eims).length > 0) ||
                (data.trainings && Object.keys(data.trainings).length > 0) ? (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                    {Object.keys(data.billable).length -
                      billableSubmittedTask +
                      Object.keys(data.not_billable).length +
                      Object.keys(data.form25task).length +
                      Object.keys(data.form13task).length +
                      Object.keys(data.missing_eims).length +
                      (data.trainings
                        ? Object.keys(data.trainings).length
                        : 0) +
                      Object.keys(data.contact_attempt_services).length +
                      Object.keys(data.missing_sf_sessions).length}
                  </span>
                ) : null}

                <div>
                  {dataStr.length > 0 ? (
                    <>
                      <h5 className="ms-2">{dataStr}</h5>
                      <p className="ms-2">
                        Please complete tasks to ensure timely payments and full
                        compliance
                      </p>
                    </>
                  ) : (
                    <>
                      <h5 className="ms-2"></h5>
                      <p className="ms-2">No Tasks Due</p>
                    </>
                  )}
                </div>
                <div className="my-auto">
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
              </div>
            </Link>
            <div className="d-flex align-items-center justify-content-end">
              <i
                className="fa fa-refresh me-2"
                aria-hidden="true"
                onClick={RefreshHomeApi}
              >
                {" "}
              </i>
              {lastUpdatedTime ? (
                <>
                  {" "}
                  <p className="mb-0"> - Last updated {lastUpdatedTime} </p>
                </>
              ) : (
                <>
                  {" "}
                  <p className="mb-0"> - Last updated 0m</p>
                </>
              )}
            </div>
          </div> */}

          <div className="box position-relative p-3 mb-3 rounded">
            <h5 className="ms-2 mb-2">
              <strong>Tasks</strong>
            </h5>
            <Link to="/task" className="linkbox">
              <div className="d-flex justify-content-between">
                {(data.billable && Object.keys(data.billable).length > 0) ||
                (data.not_billable &&
                  Object.keys(data.not_billable).length > 0) ||
                (data.missing_eims &&
                  Object.keys(data.missing_eims).length > 0) ||
                (data.trainings && Object.keys(data.trainings).length > 0) ? (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                    {billableSubmittedTask +
                      Object.keys(data.not_billable).length +
                      // Object.keys(data.form25task).length +
                      Object.keys(data.form13task).length +
                      Object.keys(data.missing_eims).length +
                      (data.trainings
                        ? Object.keys(data.trainings).length
                        : 0) +
                      Object.keys(data.contact_attempt_services).length +
                      Object.keys(data.missing_sf_sessions).length}
                  </span>
                ) : null}
                <div>
                  {dataStr.length > 0 ? (
                    <>
                      {(billableSubmittedTask && billableSubmittedTask > 0) ||
                      (data.not_billable &&
                        Object.keys(data.not_billable).length > 0) ||
                      // (data.form25task &&
                      //   Object.keys(data.form25task).length > 0) ||
                      (data.missing_eims &&
                        Object.keys(data.missing_eims).length > 0) ||
                      (data.trainings &&
                        Object.keys(data.trainings).length > 0) ? (
                        <h5 className="ms-2">
                          {
                            billableSubmittedTask +
                            Object.keys(data.not_billable).length +
                            // Object.keys(data.form25task).length +
                            Object.keys(data.form13task).length +
                            Object.keys(data.missing_eims).length +
                            (data.trainings
                              ? Object.keys(data.trainings).length
                              : 0) +
                            Object.keys(data.contact_attempt_services).length +
                            Object.keys(data.missing_sf_sessions).length}
                          <span className="ms-2">Open Items</span>
                        </h5>
                      ) : null}
                      {/* <h5 className="ms-2">Open Items</h5> */}
                      <p className="ms-2">
                        Please complete tasks to ensure timely payments and full
                        compliance
                      </p>
                    </>
                  ) : (
                    <>
                      <h5 className="ms-2"></h5>
                      <p className="ms-2">No Tasks Due</p>
                    </>
                  )}
                </div>
                <div className="my-auto">
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
              </div>
            </Link>
            <div className="d-flex align-items-center justify-content-end">
              <i
                className="fa fa-refresh me-2"
                aria-hidden="true"
                onClick={RefreshHomeApi}
              >
                {" "}
              </i>
              {lastUpdatedTime ? (
                <>
                  {" "}
                  <p className="mb-0"> - Last updated {lastUpdatedTime} </p>
                </>
              ) : (
                <>
                  {" "}
                  <p className="mb-0"> - Last updated 0m</p>
                </>
              )}
            </div>
          </div>

          <Link to="/child" className="linkbox">
            <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5 className="ms-2">Children</h5>
                <p className="ms-2">
                  Click here to log sessions and view past sessions
                </p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </Link>

          <Link to="/availability" className="linkbox">
            <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5 className="ms-2">Your Availability</h5>
                <p className="ms-2">
                  Keep your availability up to date so our team can help fill
                  your schedule
                </p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </Link>

          <Link to="/pay-stubb" className="linkbox">
            <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5 className="ms-2">Pay Stubs</h5>
                <p className="ms-2">Past, pending and future pay stubs</p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </Link>

          {/* <div className="box d-flex justify-content-between p-3 mb-3 rounded">
            <div>
              <h5 className="ms-2">Additional Resources</h5>
              <p className="ms-2">EIMS Guidelines, Common Q&A and More</p>
            </div>
            <div className="my-auto">
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          </div> */}

          {/* <div className='box d-flex justify-content-between p-3 mb-3 rounded'>
          <div>
            <h5 className='ms-2'>Billing</h5>
            <p className='ms-2'>See billing statements </p>
          </div>
          <div className='my-auto'>
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div> */}

          <Link to="/documents" className="linkbox">
            <div className="box d-flex justify-content-between p-3 mb-3 rounded">
              <div>
                <h5 className="ms-2">Documents</h5>
                <p className="ms-2">Upload and download important documents</p>
              </div>
              <div className="my-auto">
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </Link>

          {/* <div className='box d-flex justify-content-between p-3 mb-3 rounded'>
        <div>
          <h5 className='ms-2'>Upload CSV File</h5>
        </div>
        <div className='my-auto'>
          <Link to="/file/password/">
            <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </div>
      </div> */}
        </div>
      )}
      {alertItems.length > 0 && (
        <>
          <ToastContainer style={{ top: "7rem", position: "fixed" }} />
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              {alertItems.length > 0 && alertItems[currentPage - 1] && (
                <div key={alertItems[currentPage - 1].key}>
                  <p className="text-center">
                    <strong>{alertItems[currentPage - 1].title}</strong>
                  </p>
                  <p className="text-center mx-2">
                    {alertItems[currentPage - 1].description}
                  </p>
                  {alertItems[currentPage - 1].buttons.map((button, index) => (
                    <div className="text-center my-3" key={index}>
                      <button
                        type="button"
                        className="w-50 btn btn-sm py-2"
                        style={button.style}
                        onClick={button.onClick}
                      >
                        {button.label}
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {alertItems.length > 1 && (
                <div className="d-flex justify-content-center gap-3">
                  {alertItems.map((_, index) => (
                    <span
                      key={index}
                      className={`py-1 px-2 rounded  ${
                        currentPage === index + 1 ? "bg-dark text-white" : ""
                      }`}
                      onClick={() => setCurrentPage(index + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      {index + 1}
                    </span>
                  ))}
                </div>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default Home;

function handalConvertString(cookieData, billableSubmittedTask) {
  let str = "";
  console.log("cookieData str :", str);
  str += `${
    Object.keys(cookieData?.billable).length - billableSubmittedTask > 0
      ? `${
          Object.keys(cookieData?.billable).length - billableSubmittedTask
        } Billing Form(s)` + ", "
      : ""
  }`;

  str += `${
    Object.keys(cookieData?.not_billable).length > 0
      ? `${Object.keys(cookieData?.not_billable).length} Missing signature(s)` +
        ", "
      : ""
  }`;

  str += `${
    Object.keys(cookieData?.missing_eims).length +
      cookieData?.missing_sf_sessions?.length >
    0
      ? `${
          Object.keys(cookieData?.missing_eims).length +
          cookieData?.missing_sf_sessions?.length
        } Session Logging Issue(s)` + ", "
      : ""
  }`;

  str += `${
    cookieData?.form13task?.length > 0
      ? `${cookieData?.form13task?.length} Open Form 13(s) needs to be completed` +
        ", "
      : ""
  }`;

  str += `${
    cookieData?.trainings?.length > 0
      ? `${cookieData?.trainings?.length} Therapist Training(s)` + ", "
      : ""
  }`;

  str += `${
    cookieData?.contact_attempt_services?.length > 0
      ? `${cookieData?.contact_attempt_services?.length} Parent Contact Attempt(s)` +
        ", "
      : ""
  }`;
  return str.slice(0, str.length - 2);
}
