import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
} from "./localStorageUtil";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function EIMSTask() {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const token = cookie.get("logindata");

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChild, setSelectedChild] = useState("");
  const [clickedTasks, setClickedTasks] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    if (getLocalStorageWithExpiration("task-data")) {
      setIsLoading(true);
      const cookieData = getLocalStorageWithExpiration("task-data");
      setData(cookieData);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const { access_token, sf_id } = token;
        const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);
        setData(response.data);
        setLocalStorageWithExpiration("task-data", response.data, 30);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (description === "") {
      toast.warning("Description required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      setSubmitting(true);
      const { access_token, sf_id } = token;
      await axios.post(
        APIEndpoints.CREATE_CASE,
        {
          case_type: "Eims",
          therapist_id: sf_id,
          session_id: clickedTasks,
          case_category: "billing issue",
          case_details: "Missing session on EIMs",
          description: description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      setSubmitting(false);
      handleClose();
      setDescription("");
      toast.success("Successfully submitted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      handleClose();
      setDescription("");
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSubmitting(false);
      console.error("Error submitting data:", error);
    }
  };

  const uniqueChildNames = data.missing_eims
    ? [
        ...new Set(
          Object.values(data.missing_eims).map(
            (item) => item.child_service_name.split(" - ")[0]
          )
        ),
      ]
    : [];

  const handleSelectChange = (event) => {
    setSelectedChild(event.target.value);
  };

  const filteredData = selectedChild
    ? Object.values(data.missing_eims).filter((item) =>
        item.child_service_name.startsWith(selectedChild)
      )
    : [];

  return (
    <>
      <ToastContainer />
      <Header />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5">
          <div className="my-2">
            <p className="mb-0">
              <strong>Session Missing on EIMS</strong>
            </p>
            <p>
              Please ensure these sessions are properly logged on EIMS. If you
              have already done so, please allow up to 3 days for us to
              reconcile them with your TA session logs.
            </p>
          </div>
          <div className="my-3">
            <p>
              <strong>
                <i className="fa fa-filter" aria-hidden="true"></i> Filter Child
              </strong>
            </p>
            <select
              className="form-select"
              aria-label="Select Child Name"
              onChange={handleSelectChange}
              value={selectedChild}
            >
              <option value="">All children</option>
              {uniqueChildNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          {filteredData.length > 0 ? (
            filteredData.map((value, index) => (
              <div key={index} className="box p-3 mb-3 rounded">
                <p className="ms-2" style={{textAlign: 'justify'}}>
                  <strong>{value?.name.split("-")[0]}</strong>
                  <br />
                  {value?.name.split("-")[2]} {value?.start_time} -{" "}
                  {value?.end_time}
                  <br />
                  {/* <small className="text-danger">
                    According to NJEIS State Policy, sessions must be logged on the EIMS within 60 days of completion.
                    After that, they become non-billable and non-reimbursable. You have {Math.abs(value?.days_remaining)} days
                    remaining to log this session
                  </small> */}
                </p>
              </div>
            ))
          ) : (
            <div>
              {data.missing_eims &&
              Object.values(data.missing_eims).length > 0 ? (
                Object.values(data.missing_eims).map((value, index) => (
                  <div
                    key={index}
                    className="box d-flex flex-wrap justify-content-between p-3 mb-3 rounded"
                  >
                    <div>
                      <p className="ms-2" style={{textAlign: 'justify'}}>
                        <strong>{value?.name.split("-")[0]}</strong>
                        <br />
                        {value?.name.split("-")[2]} {value?.start_time} -{" "}
                        {value?.end_time}
                        <br />
                        {/* <small className="text-danger">
                          According to NJEIS State Policy, sessions must be logged on the EIMS within 60 days of completion.
                          After that, they become non-billable and non-reimbursable. You have {Math.abs(value?.days_remaining)} days
                          remaining to log this session
                        </small> */}
                      </p>
                    </div>
                    <div className="my-auto">
                      {/* Uncomment and implement if needed */}
                      {/* <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => {
                          handleShow();
                          setDescription("");
                          setClickedTasks(value.id);
                        }}
                      >
                        Need Assistance
                      </button> */}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center">There is nothing to process</p>
              )}
            </div>
          )}
        </div>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form-floating">
            <textarea
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              style={{ height: "100px" }}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <label htmlFor="floatingTextarea2">
              Please describe your issue here
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setDescription("");
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={submitting}
          >
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EIMSTask;
